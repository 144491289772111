import React, { useState } from "react";
import { CustInput } from "../../../common/CustInput/CustInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const CompanyDetail = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState(true);
  const [pagevalues, setpagevalues] = useState({
    name: "",
    email: "",
    phone: "",
    create_password: "",
    confirm_password: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    name: "",
    email: "",
    phone: "",
    create_password: "",
    confirm_password: "",
  });
  return (
    <>
      <div className="company_detail_section">
        <div
          className="back_btn"
          onClick={() => {
            nav("/");
          }}
        >
          <SvgCollection {...{ type: "leftarrow" }} />
          Back
        </div>
        <div className="comp_detl_title">Let’s get started</div>
        <div className="comp_detl_desc">
          Enter your details and we can get you set up and ready to go.
        </div>
        <div className="comp_detl_form">
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.name,
                error: errpagevalues.name,
                id: "name",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      name: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      name: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Company Name*",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.email,
                error: errpagevalues.email,
                id: "email",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      email: target.value,
                    }));
                    seterrpagevalues({
                      email:
                        !target.value ||
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                          target.value
                        ),
                    });
                  },
                },
              },
              data: {
                placeholder: "Business Email*",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.phone,
                error: errpagevalues.phone,
                id: "phone",
                event: {
                  change: ({ target }) => {
                    if (!/^\d*$/i.test(target.value)) {
                      return;
                    }
                    const cleanedInput = target.value;
                    if (cleanedInput.length > 10) {
                      return;
                    }
                    setpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput.length != 10,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Phone Number*",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: password ? "password" : "text",
                value: pagevalues.create_password,
                error: errpagevalues.create_password,
                id: "create_password",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      create_password: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      create_password: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Create Password",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: password ? "password" : "text",
                value: pagevalues.confirm_password,
                error: errpagevalues.confirm_password,
                id: "confirm_password",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      confirm_password: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      confirm_password: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Confirm Password",
                prefix: "",
                type: "",
                suffix: (
                  <div
                    className="password"
                    onClick={() => {
                      setPassword((prev) => !prev);
                    }}
                  >
                    <SvgCollection {...{ type: "eye" }} />
                  </div>
                ),
              },
            }}
          />
        </div>
        <div className="comp_detl_terms">
          By Clicking “Continue”, You Agree to Our <span>Terms of Use</span> and{" "}
          <span>Privacy Policy</span>
        </div>
        <div
          className="comp_detl_btn"
          onClick={() => {
            // if (
            //   !pagevalues.name.trim() ||
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
            //     pagevalues.email
            //   ) ||
            //   pagevalues.phone.length < 10 ||
            //   !pagevalues.create_password ||
            //   !pagevalues.confirm_password ||
            //   pagevalues.confirm_password != pagevalues.create_password
            // ) {
            //   seterrpagevalues((prev) => ({
            //     ...prev,
            //     name: !pagevalues.name.trim(),
            //     email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
            //       pagevalues.email
            //     ),
            //     phone: pagevalues.phone.length < 10,
            //     create_password: !pagevalues.create_password,
            //     confirm_password:
            //       !pagevalues.confirm_password ||
            //       pagevalues.confirm_password != pagevalues.create_password,
            //     // confirm_password:
            //     //   pagevalues.confirm_password != pagevalues.create_password,
            //   }));
            //   return;
            // }
            dispatch({
              type: "setAppState",
              subType: "user",
              payload: {
                name: pagevalues.name,
                email: pagevalues.email,
                phone: pagevalues.phone,
                create_password: pagevalues.create_password,
                confirm_password: pagevalues.confirm_password,
                type: "company",
                // token: user.token,
              },
            });
            nav("/company/verify-otp");
          }}
        >
          Continue
        </div>
      </div>
    </>
  );
};
