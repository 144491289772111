import React from "react";

export const RegisStructure = ({ header, body }) => {
  return (
    <div className="regis_str">
      <div className="regis_header">
        {header.head && (
          <div className="regis_inner_header">{header?.head}</div>
        )}
        {header.body && <div className="regis_inner_body">{header.body}</div>}
      </div>
      {body && <div className="regis_body">{body}</div>}
    </div>
  );
};
