import React, { useState } from "react";
import { LoginNav } from "../../../common/Navbar/LoginNav";
import { useNavigate } from "react-router-dom";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const LoginPages = ({ children }) => {
  const nav = useNavigate();
  return (
    <>
      <div className="login_container">
        <LoginNav
          {...{
            data: {
              title: <>Don’t have an account?</>,
              btnname: "Sign up",
              onclick: () => {
                nav("/");
              },
            },
          }}
        />
        {children}
      </div>
    </>
  );
};
