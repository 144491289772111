import React, { useState, useEffect } from "react";

export const SearchInput = ({
  onSearch,
  placeholder = "Search...",
  debounceTime = 300,
  prefix = null,
  suffix = null,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  // Debounce the input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, debounceTime);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, debounceTime]);

  // Trigger search when the debounced term changes
  useEffect(() => {
    onSearch(debouncedTerm);
  }, [debouncedTerm, onSearch]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="cust_serach_input">
      {prefix && <div className="input_prefix">{prefix}</div>}
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {suffix && <div className="input_suffix">{suffix}</div>}
    </div>
  );
};
