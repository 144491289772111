import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeftSection } from "../LeftSection";
import { CompanyDetail } from "./CompanyDetail";
import { IndividualDetail } from "./IndividualDetail";
import { VerifyOtp } from "./VerifyOtp";
import { SelectionType } from "./SelectionType";
import { Registration } from "./Registration";
import { GetBack } from "./GetBack";

export const CommonSignup = () => {
  const { type = "", page = "" } = useParams();
  const SliderData = [
    {
      top_img: "https://imgur.com/JlpAbJn.png",
      bottom_title: "CREATE & MANAGE ALL YOUR PROJECTS",
      bottom_desc: "Get Organized and Stay on Top of Your Projects with Ease!",
    },
    {
      top_img: "https://imgur.com/JlpAbJn.png",
      bottom_title: "CREATE & MANAGE ALL YOUR PROJECTS",
      bottom_desc: "Get Organized and Stay on Top of Your Projects with Ease!",
    },
  ];
  // const [nav, setnav] = useState("");
  // useEffect(() => {
  //   if (type) {
  //     setnav(page);
  //     if (type == "company" && page == "detail") {
  //       setnav("company_detail");
  //     }
  //     if (type == "individual" && page == "detail") {
  //       setnav("individual_detail");
  //     }
  //   }
  // }, [type, page]);

  const PageList = {
    "": <SelectionType />,
    detail: { company: <CompanyDetail />, individual: <IndividualDetail /> }[
      type
    ],
    "verify-otp": <VerifyOtp />,
    registration: <Registration />,
    getback: <GetBack />,
  };
  console.log(type, page);

  return (
    <div className="signup_container">
      {page != "registration" ? (
        <div className="signup_left">
          <LeftSection
            {...{
              SliderData,
            }}
          />
        </div>
      ) : (
        ""
      )}
      <div className="signup_right">
        {/* {PageList.filter((el) => el.name == nav).map((el, i) => el.comp)} */}

        {PageList[page]}
      </div>
    </div>
  );
};
