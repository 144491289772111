import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoginNav } from "../../common/Navbar/LoginNav";
import { SvgCollection } from "../../common/Image/SvgCollection";

export const AccountNav = ({ children }) => {
  const nav = useNavigate();
  const { type = "", subtype = "", currentshow = "" } = useParams();
  // console.log(type);

  return (
    <>
      <LoginNav
        {...{
          data:
            type == "individual"
              ? {
                  title: (
                    <div className="account_nav">
                      <SvgCollection {...{ type: "shuttle" }} />
                      <div className="account_nav_sep"></div>
                      Upgrade to Company to add users
                    </div>
                  ),
                  btnname: "Upgrade",
                  onclick: () => {
                    // nav("/");
                  },
                }
              : "",
        }}
      />
      {children}
    </>
  );
};
