import React from "react";

export const WelcomeResetPg = () => {
  return (
    <div className="welcomeresetpg_section">
      <div className="welcomeresetpg_blank"></div>
      <div className="welcomeresetpg_title">Welcome Back!</div>
      <div className="welcomeresetpg_desc">
        Your password has been reset successfully. Catch Up on New Design
        Inspirations and many more...
      </div>
      <div className="welcomeresetpg_btn">Start Exploring</div>
    </div>
  );
};
