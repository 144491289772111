import React, { useState } from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { CustInput } from "../../../common/CustInput/CustInput";

export const AccCompDetail = () => {
  const [pagevalues, setpagevalues] = useState({
    picture: "",
    name: "Dhiraj Ambekar",
    email: "dhiraj@email.com",
    phone: "9082279397",
    gstnumber: "dhirajambekar",
    registered_address:
      "Flat No. 202, Tower B Green Valley Apartments Sector 12, Dwarka New Delhi - 110075 India",
    shipping_address: "House No.67, Sector 9 R.K Puram New Delhi - 110022",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    picture: "",
    name: "",
    email: "",
    phone: "",
    gstnumber: "",
    registered_address: "",
    shipping_address: "",
  });
  const formDataImage = (e, type) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setpagevalues((prev) => ({
      ...prev,
      [type]: imageUrl,
    }));
    // ImageToLink(e.target.files[0], ({ status, response }) => {
    //   if (status) {
    //   }
    // });
  };

  return (
    <div className="acc_comp_detail">
      <div className="acc_comp_detail_title">Company Details</div>
      <div className="acc_comp_image_edit">
        <div className="acc_comp_image">
          {pagevalues?.picture ? (
            <div className="upload_image_svg">
              <div className="svg_design">
                <img src={pagevalues?.picture} />
              </div>
            </div>
          ) : (
            <div className="upload_image_svg">
              <div className="svg_design">
                <div className="svg_design_inner">
                  <SvgCollection {...{ type: "uploadimage" }} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="acc_comp_edit_btn">
          {[
            {
              name: "Camera",
              type: "image",
              multiSelect: false,
              capture: false,
              input: {
                valids: "",
              },
              input_filter: ["image/*"],
              click: () => {},
            },
          ].map((el, i) => (
            <label key={i} className="img_label" htmlFor={el.name}>
              <input
                type="file"
                id={el.name}
                capture={el.capture}
                {...{
                  ...(el.input_filter.length
                    ? { accept: el.input_filter }
                    : {}),
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={(e) => {
                  formDataImage(e, "picture");
                }}
                multiple={el.multiSelect}
              />
              <div className="upload_image_title">Edit logo</div>
            </label>
          ))}
        </div>
      </div>
      <div className="acc_comp_form">
        <div className="acc_comp_basic_detail">
          <div>
            <div className="from_input_title">Company Name</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.name,
                  error: errpagevalues.name,
                  id: "name",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        name: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        name: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Company Name",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <div className="from_input_title">Mobile Number</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.phone,
                  error: errpagevalues.phone,
                  id: "phone",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        phone: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        phone: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Mobile Number",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <div className="from_input_title">Business Email</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.email,
                  error: errpagevalues.email,
                  id: "email",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        email: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        email: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Email",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <div className="from_input_title">GST Number</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.gstnumber,
                  id: "gstnumber",
                  event: {
                    change: ({ target }) => {},
                  },
                },
                data: {
                  placeholder: "GST Number",
                  suffix: "",
                  type: "",
                },
              }}
            />
          </div>
        </div>
        <div className="acc_comp_address">
          <div className="acc_comp_address_title">Address Details</div>
          <div>
            <div className="from_input_title">Registered Address</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.registered_address,
                  error: errpagevalues.registered_address,
                  id: "registered_address",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        registered_address: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        registered_address: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Registered Address",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <div className="from_input_title">Shipping Address</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.shipping_address,
                  error: errpagevalues.shipping_address,
                  id: "shipping_address",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        shipping_address: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        shipping_address: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Shipping Address",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="acc_comp_btn">Save Changes</div>
    </div>
  );
};
