import React from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const HomeNav = ({ body }) => {
  return (
    <div className="home_nav_section">
      <SvgCollection {...{ type: "logo" }} />
      {body}
    </div>
  );
};
