import React, { useState } from "react";
import { LoginNav } from "../../common/Navbar/LoginNav";
import { HomeNav } from "./Comp/HomeNav";
import { SearchInput } from "../../common/CustInput/SearchInput";
import { SvgCollection } from "../../common/Image/SvgCollection";
import { useNavigate, useParams } from "react-router-dom";
import { CustInput } from "../../common/CustInput/CustInput";

export const HomePage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const { type } = useParams();
  const nav = useNavigate();
  const [AddPorjec, setAddPorject] = useState([]);
  const [LocationModal, setLocationModal] = useState(false);
  const [pincode, setpincode] = useState("");
  const handleSearch = (term) => {
    // Perform your search logic here, e.g., filtering a list or making an API call
    console.log("Searching for:", term);
    // Example: Set search results based on the search term
    // setSearchResults(filteredResults);
  };
  const innerPages = [
    "Flooring",
    "Sanitary",
    "Paint",
    "Surfaces",
    "Fabrics",
    "Lights",
    "Electricals",
  ];
  //   console.log(type);
  const marbleTilesData = [
    {
      name: "Carrara White",
      type: "Marble",
      company_name: "Luxury Stoneworks",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Carrara+White",
      id: 1,
    },
    {
      name: "Calacatta Gold",
      type: "Marble",
      company_name: "Imperial Marbles Inc.",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Calacatta+Gold",
      id: 2,
    },
    {
      name: "Emperador Dark",
      type: "Marble",
      company_name: "Prestige Tiles Ltd.",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Emperador+Dark",
      id: 3,
    },
    {
      name: "Statuario",
      type: "Marble",
      company_name: "Classic Marble Co.",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Statuario",
      id: 4,
    },
    {
      name: "Crema Marfil",
      type: "Marble",
      company_name: "Elegant Surfaces",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Crema+Marfil",
      id: 5,
    },
    {
      name: "Thassos White",
      type: "Marble",
      company_name: "Premium Marble Works",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Thassos+White",
      id: 6,
    },
    {
      name: "Nero Marquina",
      type: "Marble",
      company_name: "Blackstone Marble Ltd.",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Nero+Marquina",
      id: 7,
    },
    {
      name: "Botticino",
      type: "Marble",
      company_name: "StoneArt Creations",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Botticino",
      id: 8,
    },
    {
      name: "Rosa Levante",
      type: "Marble",
      company_name: "Marble Masters Co.",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Rosa+Levante",
      id: 9,
    },
    {
      name: "Volakas",
      type: "Marble",
      company_name: "Hellenic Stoneworks",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Volakas",
      id: 10,
    },
    {
      name: "Volakas",
      type: "Marble",
      company_name: "Hellenic Stoneworks",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Volakas",
      id: 12,
    },
    {
      name: "Volakas",
      type: "Marble",
      company_name: "Hellenic Stoneworks",
      img: "https://dummyimage.com/600x400/cccccc/ffffff&text=Volakas",
      id: 13,
    },
  ];
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log("Latitude: " + latitude + " Longitude: " + longitude);

  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
    }
  }

  return (
    <>
      {LocationModal ? (
        <div className="location_modal_container">
          <div className="location_modal_box">
            <div className="location_modal_heading">
              <div>
                <SvgCollection
                  {...{
                    type: "location",
                  }}
                />
              </div>
              <div className="location_modal_heading_sep"></div>
              <div>
                <div className="location_modal_heading_title">Location</div>
                <div className="location_modal_heading_desc">
                  This will help us to provide personalized experience!
                </div>
              </div>
            </div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pincode,
                  id: "pincode",
                  event: {
                    change: ({ target }) => {
                      setpincode(target.value);
                    },
                  },
                },
                data: {
                  placeholder: "Enter Pincode or City*",
                  prefix: "",
                  suffix: (
                    <div
                      className="pincode_detect"
                      onClick={() => {
                        getLocation();
                      }}
                    >
                      <SvgCollection
                        {...{
                          type: "detect",
                        }}
                      />{" "}
                      Detect
                    </div>
                  ),
                  type: "",
                },
              }}
            />
            <div className="location_modal_box_btns">
              <div
                className="location_modal_box_btns_cnl"
                onClick={() => {
                  setLocationModal(false);
                }}
              >
                Later
              </div>
              <div
                className="location_modal_box_btns_conf"
                onClick={() => {
                  setLocationModal(false);
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="home_top_screen">
        <HomeNav
          {...{
            body: (
              <div className="home_nav_body">
                <SearchInput
                  {...{
                    onSearch: handleSearch,
                    placeholder: "Search materials, products, brands and more",
                    prefix: (
                      <SvgCollection
                        {...{
                          type: "search",
                        }}
                      />
                    ),
                    suffix: "",
                  }}
                />
                <div className="home_nav_pages">
                  <div>
                    <SvgCollection
                      {...{
                        type: "notification",
                      }}
                    />
                  </div>
                  <div>
                    <SvgCollection
                      {...{
                        type: "favorite",
                      }}
                    />
                  </div>
                  <div>
                    <SvgCollection
                      {...{
                        type: "bx_cart",
                      }}
                    />
                  </div>
                  <div>
                    <SvgCollection
                      {...{
                        type: "iconamoon_profile",
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          }}
        />
        <div className="home_inner_page">
          <div
            className="home_location"
            onClick={() => {
              setLocationModal(true);
            }}
          >
            <SvgCollection
              {...{
                type: "location2",
              }}
            />
            <div>My Location</div>
            <SvgCollection
              {...{
                type: "downarrow",
              }}
            />
          </div>
          <div className="home_box_section">
            {innerPages?.map((el, i) => (
              <div
                className={el.toLowerCase() == type ? "active" : ""}
                onClick={() => {
                  nav(`/home/${el.toLowerCase()}`);
                }}
              >
                {el}
              </div>
            ))}
          </div>
        </div>
        <div className="home_inner_filters_section">
          <div className="home_inner_filters">
            <SvgCollection
              {...{
                type: "filter",
              }}
            />
            Filters
          </div>
          <div className="home_inner_filters_sep"></div>
          <div className="home_inner_filters_box_all">All</div>
          <div className="home_inner_filters_box">
            Toilet Area
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.59834 6.72725C5.81801 6.50758 6.17417 6.50758 6.39384 6.72725L9.74609 10.0795L13.0984 6.72725C13.318 6.50758 13.6741 6.50758 13.8938 6.72725C14.1135 6.94692 14.1135 7.30308 13.8938 7.52272L10.1438 11.2727C10.0384 11.3782 9.89527 11.4375 9.74609 11.4375C9.59692 11.4375 9.45382 11.3782 9.34837 11.2727L5.59834 7.52272C5.37868 7.30308 5.37868 6.94692 5.59834 6.72725Z"
                fill="#314027"
              />
            </svg>
          </div>
          <div className="home_inner_filters_box">
            Shower Area
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.59834 6.72725C5.81801 6.50758 6.17417 6.50758 6.39384 6.72725L9.74609 10.0795L13.0984 6.72725C13.318 6.50758 13.6741 6.50758 13.8938 6.72725C14.1135 6.94692 14.1135 7.30308 13.8938 7.52272L10.1438 11.2727C10.0384 11.3782 9.89527 11.4375 9.74609 11.4375C9.59692 11.4375 9.45382 11.3782 9.34837 11.2727L5.59834 7.52272C5.37868 7.30308 5.37868 6.94692 5.59834 6.72725Z"
                fill="#314027"
              />
            </svg>
          </div>
          <div className="home_inner_filters_box">
            Basin Area
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.59834 6.72725C5.81801 6.50758 6.17417 6.50758 6.39384 6.72725L9.74609 10.0795L13.0984 6.72725C13.318 6.50758 13.6741 6.50758 13.8938 6.72725C14.1135 6.94692 14.1135 7.30308 13.8938 7.52272L10.1438 11.2727C10.0384 11.3782 9.89527 11.4375 9.74609 11.4375C9.59692 11.4375 9.45382 11.3782 9.34837 11.2727L5.59834 7.52272C5.37868 7.30308 5.37868 6.94692 5.59834 6.72725Z"
                fill="#314027"
              />
            </svg>
          </div>
          <div className="home_inner_filters_box">
            Kitchen
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.59834 6.72725C5.81801 6.50758 6.17417 6.50758 6.39384 6.72725L9.74609 10.0795L13.0984 6.72725C13.318 6.50758 13.6741 6.50758 13.8938 6.72725C14.1135 6.94692 14.1135 7.30308 13.8938 7.52272L10.1438 11.2727C10.0384 11.3782 9.89527 11.4375 9.74609 11.4375C9.59692 11.4375 9.45382 11.3782 9.34837 11.2727L5.59834 7.52272C5.37868 7.30308 5.37868 6.94692 5.59834 6.72725Z"
                fill="#314027"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="home_body">
        {marbleTilesData.map((el, i) => (
          <div key={i} className="product_box">
            <img src={el.img} />
            <div className="product_box_type">{el.type}</div>
            <div className="product_box_name">{el.name}</div>
            <div className="product_box_comp_name">{el.company_name}</div>
            <div
              className={
                "product_box_comp_btn" +
                (AddPorjec.map((el1) => el1.id).includes(el.id)
                  ? " active"
                  : "")
              }
              onClick={() => {
                if (AddPorjec.map((el1) => el1.id).includes(el.id)) {
                  setAddPorject((prev) => [
                    ...prev.filter((el2) => el2.id != el.id),
                  ]);
                } else {
                  setAddPorject((prev) => [...prev, el]);
                }
              }}
            >
              Add to Project
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
