import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RegisStructure } from "../../../common/RegisStructure";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { AccCompDetail } from "./AccCompDetail";
import { AccCompMem } from "./AccCompMem";
import { AccTeamDetail } from "./AccTeamDetail";

export const AccountCompany = () => {
  const { type = "", subtype = "", currentshow = "" } = useParams();
  const nav = useNavigate();
  const pages = {
    detail: { owner: <AccCompDetail />, team: <AccTeamDetail /> }[subtype],
    member: <AccCompMem />,
  };
  return (
    <div className="acc_comp_section">
      <RegisStructure
        {...{
          header: {
            head: (
              <div className="acc_comp_backbtn">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    nav(`/`);
                  }}
                >
                  <path
                    d="M17.4166 8.70813H3.49433L7.68462 4.51784C7.76023 4.44481 7.82054 4.35746 7.86203 4.26087C7.90353 4.16428 7.92536 4.0604 7.92628 3.95528C7.92719 3.85017 7.90716 3.74592 7.86735 3.64863C7.82755 3.55133 7.76876 3.46294 7.69443 3.38861C7.6201 3.31428 7.53171 3.2555 7.43442 3.21569C7.33712 3.17589 7.23288 3.15585 7.12776 3.15677C7.02264 3.15768 6.91876 3.17952 6.82218 3.22101C6.72559 3.2625 6.63823 3.32281 6.5652 3.39842L1.02354 8.94009C0.875123 9.08855 0.791748 9.28988 0.791748 9.4998C0.791748 9.70972 0.875123 9.91105 1.02354 10.0595L6.5652 15.6012C6.71451 15.7454 6.91449 15.8252 7.12206 15.8234C7.32964 15.8216 7.5282 15.7383 7.67498 15.5915C7.82176 15.4447 7.90502 15.2462 7.90682 15.0386C7.90862 14.831 7.82883 14.6311 7.68462 14.4818L3.49433 10.2915H17.4166C17.6265 10.2915 17.8279 10.2081 17.9764 10.0596C18.1248 9.91113 18.2082 9.70976 18.2082 9.4998C18.2082 9.28984 18.1248 9.08847 17.9764 8.94001C17.8279 8.79154 17.6265 8.70813 17.4166 8.70813Z"
                    fill="#4D4E58"
                  />
                </svg>
                <div
                  onClick={() => {
                    nav(`/`);
                  }}
                >
                  Account Settings
                </div>
              </div>
            ),
            body: (
              <div className="acc_comp_type">
                <div
                  className={
                    currentshow == "detail" ? "acc_comp_type_active" : ""
                  }
                  onClick={() => {
                    nav(`/account/${type}/${subtype}/detail`);
                  }}
                >
                  <SvgCollection
                    {...{
                      type: "officebld",
                    }}
                  />
                  Company Details
                </div>
                <div
                  className={
                    currentshow == "member" ? "acc_comp_type_active" : ""
                  }
                  onClick={() => {
                    nav(`/account/${type}/${subtype}/member`);
                  }}
                >
                  <SvgCollection
                    {...{
                      type: "oneperson",
                    }}
                  />
                  Team Members
                </div>
              </div>
            ),
          },
          body: pages[currentshow],
        }}
      />
    </div>
  );
};
