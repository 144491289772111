import React from "react";
import { SvgCollection } from "../Image/SvgCollection";

export const LoginNav = ({ data }) => {
  return (
    <div className="login_nav_container">
      <div className="login_nav_logo">
        <SvgCollection {...{ type: "logo" }} />
      </div>
      {data.btnname ? (
        <div className="login_nav_ctr">
          <div className="login_nav_title">{data?.title}</div>
          <div
            className="login_nav_btn"
            onClick={() => {
              data.onclick();
            }}
          >
            {data?.btnname}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
