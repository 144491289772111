import React, { useState } from "react";
import { CustInput } from "../../common/CustInput/CustInput";
import { SvgCollection } from "../../common/Image/SvgCollection";

export const PasswordReset = ({ setResetPassword }) => {
  const [pagevalues, setpagevalues] = useState({
    oldpassword: "",
    newpassowrd: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    oldpassword: "",
    newpassowrd: "",
  });
  return (
    <div
      className="pass_reset_modal"
      onClick={() => {
        setResetPassword(false);
      }}
    >
      <div
        className="pass_reset_modal_box"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pass_reset_modal_heading">
          <div>
            <SvgCollection
              {...{
                type: "key",
              }}
            />
          </div>
          <div className="pass_reset_modal_heading_sep"></div>
          <div>
            <div className="pass_reset_modal_heading_title">Password Reset</div>
            <div className="pass_reset_modal_heading_desc">
              Enter the details to reset your password
            </div>
          </div>
        </div>
        <div className="pass_reset_modal_box_inputs">
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.oldpassword,
                error: errpagevalues.oldpassword,
                id: "oldpassword",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      oldpassword: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      oldpassword: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Old Password",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.newpassowrd,
                error: errpagevalues.newpassowrd,
                id: "newpassowrd",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      newpassowrd: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      newpassowrd: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "New Password",
                prefix: "",
                type: "",
              },
            }}
          />
        </div>
        <div className="pass_reset_modal_box_btns">
          <div
            className="pass_reset_modal_box_btns_cnl"
            onClick={() => {
              setResetPassword("");
            }}
          >
            Cancel
          </div>
          <div
            className="pass_reset_modal_box_btns_conf"
            onClick={() => {
              setResetPassword("success");
            }}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};
