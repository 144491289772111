import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeftSection } from "../LeftSection";
import Slider from "react-slick/lib/slider";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const SelectionType = ({ page, setpage, AllData, setAllData }) => {
  const nav = useNavigate();

  const SliderData = [
    {
      top_img: "https://imgur.com/JlpAbJn.png",
      bottom_title: "CREATE & MANAGE ALL YOUR PROJECTS",
      bottom_desc: "Get Organized and Stay on Top of Your Projects with Ease!",
    },
    {
      top_img: "https://imgur.com/JlpAbJn.png",
      bottom_title: "CREATE & MANAGE ALL YOUR PROJECTS",
      bottom_desc: "Get Organized and Stay on Top of Your Projects with Ease!",
    },
  ];
  return (
    <>
      <div className="signup_right_select">
        <div className="sele_type_title">
          How are you planning to use Materialogue?
        </div>
        <div className="sele_type_desc">
          We’ll streamline your setup experience accordingly.
        </div>
        <div className="sele_type_choice">
          <div
            className="choice1_box"
            onClick={() => {
              nav("/company/detail");
            }}
          >
            <div className="choice1_check_box">
              {AllData?.type == "company" ? (
                <SvgCollection {...{ type: "correct" }} />
              ) : (
                ""
              )}
            </div>
            <div>
              <SvgCollection {...{ type: "companypeople" }} />
            </div>
            <div className="choice1_title">Company</div>
            <div className="choice1_desc">(Upto 3 users)</div>
          </div>
          <div
            className="choice2_box"
            onClick={() => {
              nav("/individual/detail");
            }}
          >
            <div className="choice2_check_box">
              {AllData?.type == "individual" ? (
                <SvgCollection {...{ type: "correct" }} />
              ) : (
                ""
              )}
            </div>
            <div>
              <SvgCollection {...{ type: "individualpeople" }} />
            </div>
            <div className="choice2_title">Individual</div>
            <div className="choice2_desc">(Single user)</div>
          </div>
        </div>
      </div>
    </>
  );
};
