import React from "react";
import { useParams } from "react-router-dom";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const GetBack = () => {
  const { type = "", page = "" } = useParams();

  return (
    <>
      <div className="get_back_section">
        <SvgCollection {...{ type: "stopwatch" }} />
        <div className="get_back_title">We’ll get back to you!</div>
        <div className="get_back_desc">
          It will take upto <span>48 hours</span> for the account to get active
        </div>
        <div className="start_exp_btn">Start Exploring</div>
        {type == "company" ? (
          <div className="action_box">
            <div className="action_box_start_line"></div>
            <div className="action_box_detail">
              <div className="action_box_title">Next Action Step</div>
              <div className="action_box_desc">Start adding team members</div>
            </div>
            <div className="action_box_btn">+ Add</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
