import React from "react";
import { CustInput } from "../../common/CustInput/CustInput";
import { SvgCollection } from "../../common/Image/SvgCollection";

export const PasswordResetSuccess = ({ setResetPassword }) => {
  return (
    <div
      className="passwrod_success"
      onClick={() => {
        setResetPassword(false);
      }}
    >
      <div
        className="pass_reset_modal_box"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pass_reset_modal_heading">
          <div>
            <SvgCollection
              {...{
                type: "key",
              }}
            />
          </div>
          <div className="pass_reset_modal_heading_sep"></div>
          <div>
            <div className="pass_reset_modal_heading_title">Success</div>
            <div className="pass_reset_modal_heading_desc">
              The password has been reset successfully.
            </div>
          </div>
        </div>

        <div className="pass_reset_modal_box_btns">
          <div
            className="pass_reset_modal_box_btns_cnl"
            onClick={() => {
              setResetPassword("");
            }}
          >
            Cancel
          </div>
          <div
            className="pass_reset_modal_box_btns_conf"
            onClick={() => {
              setResetPassword("");
            }}
          >
            Ok
          </div>
        </div>
      </div>
    </div>
  );
};
