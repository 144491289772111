import React, { useRef, useState } from "react";
import Slider from "react-slick/lib/slider";

export const LeftSection = ({ SliderData }) => {
  const [CurrentCount, setCurrentCount] = useState(0);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCurrentCount(next);
    },
  };
  const slider_box = useRef();

  return (
    <div>
      <div className="left_login_section">
        <Slider {...settings} ref={slider_box}>
          {SliderData?.map((el, i) => (
            <div className="left_login_box" key={i}>
              <div className="left_login_top">
                <img src={el.top_img} />
              </div>
              <div className="left_login_bottom">
                <div className="left_login_bottom_title">{el.bottom_title}</div>
                <div className="left_login_bottom_desc">{el.bottom_desc}</div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="left_login_dots">
          {SliderData.map((el, i) => (
            <div
              className={CurrentCount == i ? "left_login_dots_active" : ""}
              onClick={() => {
                slider_box.current.slickGoTo(i);
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};
