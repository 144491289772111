import React, { useState } from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { useNavigate } from "react-router-dom";
import { CustInput } from "../../../common/CustInput/CustInput";

export const ForgetPassword = () => {
  const nav = useNavigate();
  const [login_key, setlogin_key] = useState("");
  const [errlogin_key, seterrlogin_key] = useState("");
  return (
    <div className="forget_pass_section">
      <div
        className="back_btn"
        onClick={() => {
          nav("/login/password");
        }}
      >
        <SvgCollection {...{ type: "leftarrow" }} />
        Back
      </div>{" "}
      <div className="forget_pass_title">Recover your Password</div>
      <div className="forget_pass_desc">
        <span>Enter the email </span>
        that you used when you signed up to recover your password. You will
        receive a<span> password reset link</span>
      </div>
      <div>
        <CustInput
          {...{
            inpType: "type2",
            attr: {
              type: "text",
              value: login_key,
              error: errlogin_key,
              id: "name",
              event: {
                change: ({ target }) => {
                  setlogin_key(target.value);
                  seterrlogin_key(!target.value);
                },
              },
            },
            data: {
              placeholder: "Email*",
              prefix: "",
              type: "",
            },
          }}
        />
      </div>
      <div className="forget_pass_ctr">
        <div
          className="forget_pass_send_link"
          onClick={() => {
            nav("/login/resetpassword");
          }}
        >
          Send Link
        </div>
        <div className="forget_pass_need">
          Need further assistance?
          <span>Contact Us</span>
        </div>
      </div>
    </div>
  );
};
