import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const VerifyOtp = () => {
  const nav = useNavigate();
  const { type, page } = useParams();
  const { user } = useSelector((state) => state.appState);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [error_otp, seterror_otp] = useState("");

  const handleInputChange = (index, value) => {
    const updatedValues = [...otpValues];
    if (updatedValues.join("").length >= 6 && value !== "") {
      return;
    }
    updatedValues[index] = value;
    setOtpValues(updatedValues);
    seterror_otp("");
    if (value && index < otpValues.length - 1) {
      const nextInput = document.getElementsByName(`otp-${index + 1}`)[0];
      nextInput.focus();
    }
    // if (updatedValues.join("").length == 6) {
    //   handle_login(updatedValues);
    // }
  };
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      const previousInput = document.getElementsByName(`otp-${index - 1}`)[0];
      previousInput.focus();
    }
  };
  const handle_login = (otpValues) => {
    if (otpValues?.join("")?.length != 6) {
      seterror_otp(true);
      return;
    }
    nav(`/${type}/registration`);
    console.log(otpValues?.join(""));
  };

  return (
    <div className="verify_otp_main">
      <div
        className="back_btn"
        onClick={() => {
          nav(`/${type}/detail`);
        }}
      >
        <SvgCollection {...{ type: "leftarrow" }} />
        Back
      </div>{" "}
      <div className="verify_otp_title">OTP Verification</div>
      <div className="verify_otp_desc">
        Please enter the OTP sent to your mobile ending in{" "}
        <span>xxxxxx{user?.phone?.slice(-4)}</span> and email address{" "}
        <span>{user?.email?.replace(/(.{5}).*(@.*)/, "$1xxxxx$2")}</span>
      </div>
      <div className="opt_stand">
        {otpValues?.map((value, index) => (
          <input
            key={index}
            type="number"
            maxLength="1"
            className={
              "opt_design " +
              (value != "" ? "opt_design_active " : "") +
              (error_otp ? " error_o" : "")
            }
            inputMode="numeric"
            name={`otp-${index}`}
            placeholder=""
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>
      <div className="opt_resend">
        Didn’t receive the code? <span>Resend</span>
      </div>
      <div
        className="otp_detl_btn"
        onClick={() => {
          handle_login(otpValues);
        }}
      >
        Continue
      </div>
    </div>
  );
};
