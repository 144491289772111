import React from "react";
import { useParams } from "react-router-dom";
import { AccountIndividual } from "./Pages/AccountIndividual";
import { AccountCompany } from "./Pages/AccountCompany";

export const AccountPage = () => {
  const { type = "", subtype = "", currentshow = "" } = useParams();
  const Accountpages = {
    individual: <AccountIndividual />,
    company: <AccountCompany />,
  };

  return <>{Accountpages[type]}</>;
};
