import React from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const AccTeamDetail = () => {
  return (
    <div className="accteamdetail_section">
      <div className="accteamdetail_comp">
        <div className="accteamdetail_comp_line"></div>
        <div className="accteamdetail_comp_title">Company Details</div>
      </div>
      <div className="accteamdetail_comp_detail">
        <div className="accteamdetail_comp_personal">
          <div className="accteamdetail_comp_image">
            <SvgCollection
              {...{
                type: "uploadimage",
              }}
            />
          </div>
          <div className="accteamdetail_comp_box">
            <div className="accteamdetail_comp_box_name">Namaste Nook</div>
            <div className="accteamdetail_comp_box_email">
              Namastenook434@gmail.com | 9999223231
            </div>
          </div>
        </div>
        <div className="accteamdetail_comp_sep"></div>
        <div className="accteamdetail_comp_private">
          <div>
            <SvgCollection
              {...{
                type: "gst",
              }}
            />
          </div>
          <div className="accteamdetail_comp_sep"></div>
          <div>
            <div className="accteamdetail_comp_gst_title">GST Number</div>
            <div className="accteamdetail_comp_gst_num">29ABCDE1234F1Z5</div>
          </div>
        </div>
      </div>
      <div className="accteamdetail_comp">
        <div className="accteamdetail_comp_line"></div>
        <div className="accteamdetail_comp_title">Address Details</div>
      </div>
      <div className="accteamdetail_address">
        <div>
          <SvgCollection
            {...{
              type: "location",
            }}
          />
        </div>
        <div className="accteamdetail_address_sep"></div>
        <div className="accteamdetail_address_detail">
          <div className="accteamdetail_address_detail_title">
            Registered Address
          </div>
          <div className="accteamdetail_address_detail_valu0">
            Flat No. 202, Tower B Green Valley Apartments Sector 12, Dwarka New
            Delhi - 110075 India
          </div>
        </div>
      </div>
      <div className="accteamdetail_address">
        <div>
          <SvgCollection
            {...{
              type: "location",
            }}
          />
        </div>
        <div className="accteamdetail_address_sep"></div>
        <div className="accteamdetail_address_detail">
          <div className="accteamdetail_address_detail_title">
            Shipping Address
          </div>
          <div className="accteamdetail_address_detail_valu0">
            House No.67, Sector 9 R.K Puram New Delhi - 110022
          </div>
        </div>
      </div>
    </div>
  );
};
