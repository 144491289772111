import React, { useEffect, useState } from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { AddEditAccount } from "../AddEditAccount";
import { useDispatch, useSelector } from "react-redux";
import { PasswordReset } from "../PasswordReset";
import { PasswordResetWorning } from "../PasswordResetWorning";
import { PasswordResetSuccess } from "../PasswordResetSuccess";
import { useParams } from "react-router-dom";

export const AccCompMem = () => {
  const { team } = useSelector((state) => state.appState);
  const dispatch = useDispatch();
  const { type = "", subtype = "", currentshow = "" } = useParams();

  const [ListOfMember, setListOfMember] = useState([
    {
      picture: "",
      name: "Namaste Nook",
      phone: "97582134542",
      email: "Namastenook@gmail.com",
      password: "Namastenook@gmail.com",
      designation: "Design Lead",
      type: "company",
      accounttype: "owner",
    },
    {
      picture: "",
      name: "Namaste Nook",
      phone: "97582134542",
      email: "Namastenook@gmail.com",
      password: "Namastenook@gmail.com",
      designation: "Interior Designer",
      type: "company",
    },
    // {
    //   picture: "",
    //   name: "Namaste Nook",
    //   phone: "97582134542",
    //   email: "Namastenook@gmail.com",
    //   password: "Namastenook@gmail.com",
    //   designation: "Interior Designer",
    //   type: "company",
    // },
  ]);
  const [AddEditAcc, setAddEditAcc] = useState(false);
  const [PasswordModalCtr, setPasswordModalCtr] = useState("");
  useEffect(() => {
    dispatch({
      type: "setAppState",
      subType: "team",
      payload: {
        ...team,
        list: ListOfMember,
      },
    });
  }, []);

  return (
    <>
      {AddEditAcc ? (
        <AddEditAccount
          {...{
            AddEditAcc,
            setAddEditAcc,
          }}
        />
      ) : (
        ""
      )}

      {
        {
          warning: (
            <PasswordResetWorning
              {...{
                setResetPassword: setPasswordModalCtr,
              }}
            />
          ),
          reset: (
            <PasswordReset
              {...{
                setResetPassword: setPasswordModalCtr,
              }}
            />
          ),
          success: (
            <PasswordResetSuccess
              {...{
                setResetPassword: setPasswordModalCtr,
              }}
            />
          ),
        }[PasswordModalCtr]
      }
      <div className="acc_comp_mem_section">
        <div className="acc_comp_mem_heading">
          <div className="acc_comp_mem_title">Team Members</div>
          <div className="acc_comp_mem_pg_num">
            <div className="acc_comp_mem_pages_gui">
              <div className="gui_num">{team?.list?.length}</div>
              of
              <div className="gui_num">3</div>
              members added in the team
            </div>
            <div className="acc_comp_mem_svgs">
              <div>
                <SvgCollection
                  {...{
                    type: "oneperson",
                  }}
                />
              </div>
              <div className="acc_comp_mem_svgs_second">
                <SvgCollection
                  {...{
                    type: "oneperson",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="acc_comp_mem_body">
          {team?.list?.map((el, i) => (
            <div className="mem_body_box">
              {el.accounttype ? (
                <div className="mem_body_box_admin">Admin</div>
              ) : (
                ""
              )}
              <div className="mem_body_box_top">
                <div className="mem_body_box_top_name">
                  <div className="mem_body_box_name">{el.name}</div>
                  <div className="mem_body_box_phone">{el.phone}</div>
                </div>
                <div className="mem_body_box_top_img">
                  {el.picture ? (
                    <img src={el.picture} />
                  ) : (
                    <SvgCollection
                      {...{
                        type: "uploadimage",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="mem_body_box_email">{el.email}</div>
              <div className="mem_body_box_password">*************</div>
              <div className="mem_body_box_designation">{el.designation}</div>

              <div className="mem_body_box_btns">
                {el.accounttype == "owner" ? (
                  <>
                    <div
                      className="mem_body_box_edit"
                      onClick={() => {
                        dispatch({
                          type: "setAppState",
                          subType: "team",
                          payload: {
                            ...team,
                            edituser: { ...el, index: i },
                            adduser: false,
                          },
                        });
                        setAddEditAcc(true);
                      }}
                    >
                      <SvgCollection
                        {...{
                          type: "edit",
                        }}
                      />
                      Edit
                    </div>
                    <div
                      className="mem_body_box_reset"
                      onClick={() => {
                        setPasswordModalCtr("warning");
                      }}
                    >
                      Reset Password
                    </div>
                  </>
                ) : subtype == "owner" ? (
                  <div className="mem_body_box_edit">Delete</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
          {team?.list?.length < 3 && subtype == "owner" ? (
            <div
              className="mem_body_box adder"
              onClick={() => {
                setAddEditAcc(true);
              }}
            >
              <div className="mem_body_box_svg">
                <div className="mem_body_box_svg_icon">
                  <SvgCollection
                    {...{
                      type: "add",
                    }}
                  />
                </div>
                <div className="mem_body_box_svg_title">Add a New Member</div>
              </div>
              <div className="mem_body_box_btns2">
                <div
                  className="mem_body_box_edit"
                  onClick={() => {
                    dispatch({
                      type: "setAppState",
                      subType: "team",
                      payload: {
                        ...team,
                        edituser: {},
                        adduser: true,
                      },
                    });
                    setAddEditAcc(true);
                  }}
                >
                  Add Member
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
