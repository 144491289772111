import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RegisStructure } from "../../../common/RegisStructure";
import { CustInput } from "../../../common/CustInput/CustInput";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const Registration = () => {
  const { user } = useSelector((state) => state.appState);
  const { type, page } = useParams();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [pagevalues, setpagevalues] = useState({
    picture: "",
    name: "",
    email: "",
    phone: "",
    url: {
      website: "",
      instagram: "",
      linkedin: "",
    },
    architecture_registration: "",
    interior_design_degree: "",
    architecture_degree: "",
  });

  useEffect(() => {
    setpagevalues((prev) => ({
      ...prev,
      name: user.name,
      email: user.email,
      phone: user.phone,
    }));
  }, [user]);

  const formDataImage = (e, type) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setpagevalues((prev) => ({
      ...prev,
      [type]: imageUrl,
    }));
    // ImageToLink(e.target.files[0], ({ status, response }) => {
    //   if (status) {
    //   }
    // });
  };

  return (
    <div className="registration_section">
      <RegisStructure
        {...{
          header: {
            head: (
              <div
                className="registration_backbtn"
                onClick={() => {
                  nav(`/${type}/detail`);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.4166 8.70813H3.49433L7.68462 4.51784C7.76023 4.44481 7.82054 4.35746 7.86203 4.26087C7.90353 4.16428 7.92536 4.0604 7.92628 3.95528C7.92719 3.85017 7.90716 3.74592 7.86735 3.64863C7.82755 3.55133 7.76876 3.46294 7.69443 3.38861C7.6201 3.31428 7.53171 3.2555 7.43442 3.21569C7.33712 3.17589 7.23288 3.15585 7.12776 3.15677C7.02264 3.15768 6.91876 3.17952 6.82218 3.22101C6.72559 3.2625 6.63823 3.32281 6.5652 3.39842L1.02354 8.94009C0.875123 9.08855 0.791748 9.28988 0.791748 9.4998C0.791748 9.70972 0.875123 9.91105 1.02354 10.0595L6.5652 15.6012C6.71451 15.7454 6.91449 15.8252 7.12206 15.8234C7.32964 15.8216 7.5282 15.7383 7.67498 15.5915C7.82176 15.4447 7.90502 15.2462 7.90682 15.0386C7.90862 14.831 7.82883 14.6311 7.68462 14.4818L3.49433 10.2915H17.4166C17.6265 10.2915 17.8279 10.2081 17.9764 10.0596C18.1248 9.91113 18.2082 9.70976 18.2082 9.4998C18.2082 9.28984 18.1248 9.08847 17.9764 8.94001C17.8279 8.79154 17.6265 8.70813 17.4166 8.70813Z"
                    fill="#4D4E58"
                  />
                </svg>
                Back
              </div>
            ),
            body: (
              <div className="registration_detail">
                <div className="no_edit_section">
                  <div className="name">{pagevalues.name}</div>
                  <div className="email_phone">
                    {pagevalues.email} | {pagevalues.phone}
                  </div>
                </div>
                <div className="upload_image">
                  {[
                    {
                      name: "Camera",
                      type: "image",
                      multiSelect: false,
                      capture: false,
                      input: {
                        valids: "",
                      },
                      input_filter: ["image/*"],
                      click: () => {},
                    },
                  ].map((el, i) => (
                    <label key={i} className="img_label" htmlFor={el.name}>
                      <input
                        type="file"
                        id={el.name}
                        capture={el.capture}
                        {...{
                          ...(el.input_filter.length
                            ? { accept: el.input_filter }
                            : {}),
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                        onChange={(e) => {
                          formDataImage(e, "picture");
                        }}
                        multiple={el.multiSelect}
                      />
                      {pagevalues?.picture ? (
                        <div className="upload_image_title">Edit logo</div>
                      ) : (
                        <div className="upload_image_title">Upload logo</div>
                      )}
                      {pagevalues?.picture ? (
                        <div className="upload_image_svg">
                          <div className="svg_design">
                            <img src={pagevalues?.picture} />
                          </div>
                        </div>
                      ) : (
                        <div className="upload_image_svg">
                          <div className="svg_design">
                            <div className="svg_design_inner">
                              <SvgCollection {...{ type: "uploadimage" }} />,
                            </div>
                          </div>
                        </div>
                      )}
                    </label>
                  ))}
                </div>
              </div>
            ),
          },
          body: (
            <div className="registration_from">
              <div className="registration_from_title">
                <div className="registration_from_line1"></div>
                <div className="registration_from_text">
                  Complete atleast <span>2</span> of the following for
                  registration
                </div>
              </div>
              <div className="registration_from_inputs">
                <div>
                  <CustInput
                    {...{
                      inpType: "type2",
                      attr: {
                        type: "text",
                        value: pagevalues.url.website,
                        // error: errpagevalues.name,
                        id: "website",
                        event: {
                          change: ({ target }) => {
                            setpagevalues((prev) => ({
                              ...prev,
                              url: {
                                ...prev.url,
                                website: target.value,
                              },
                            }));
                            // seterrpagevalues((prev) => ({
                            //   ...prev,
                            //   name: !target.value,
                            // }));
                          },
                        },
                      },
                      data: {
                        placeholder: "Website URL",
                        prefix: <SvgCollection {...{ type: "website" }} />,
                        type: "",
                      },
                    }}
                  />
                  <CustInput
                    {...{
                      inpType: "type2",
                      attr: {
                        type: "text",
                        value: pagevalues.url.instagram,
                        // error: errpagevalues.name,
                        id: "instagram",
                        event: {
                          change: ({ target }) => {
                            setpagevalues((prev) => ({
                              ...prev,
                              url: {
                                ...prev.url,
                                instagram: target.value,
                              },
                            }));
                            // seterrpagevalues((prev) => ({
                            //   ...prev,
                            //   name: !target.value,
                            // }));
                          },
                        },
                      },
                      data: {
                        placeholder: "Instagram URL",
                        prefix: <SvgCollection {...{ type: "instagram" }} />,
                        type: "",
                      },
                    }}
                  />
                  <CustInput
                    {...{
                      inpType: "type2",
                      attr: {
                        type: "text",
                        value: pagevalues.url.linkedin,
                        // error: errpagevalues.name,
                        id: "linkedin",
                        event: {
                          change: ({ target }) => {
                            setpagevalues((prev) => ({
                              ...prev,
                              url: {
                                ...prev.url,
                                linkedin: target.value,
                              },
                            }));
                            // seterrpagevalues((prev) => ({
                            //   ...prev,
                            //   name: !target.value,
                            // }));
                          },
                        },
                      },
                      data: {
                        placeholder: "LinkedIn URL",
                        prefix: <SvgCollection {...{ type: "linkedin" }} />,
                        type: "",
                      },
                    }}
                  />
                </div>
                <div>
                  <div className="file_upaloder">
                    <div className="file_upaloder_svg">
                      <SvgCollection {...{ type: "architecture" }} />
                    </div>
                    <div className="file_upaloder_title">
                      Council of Architecture Registration
                    </div>
                    <div className="file_upaloder_uploader">
                      {[
                        {
                          name: "File",
                          type: "file",
                          multiSelect: false,
                          capture: false,
                          input: {
                            valids: "",
                          },
                          input_filter: [
                            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ],
                          click: () => {},
                        },
                      ].map((el, i) => (
                        <label key={i} className="img_label" htmlFor={el.name}>
                          <input
                            type="file"
                            id={el.name}
                            capture={el.capture}
                            {...{
                              ...(el.input_filter.length
                                ? { accept: el.input_filter }
                                : {}),
                            }}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {
                              formDataImage(e, "architecture_registration");
                            }}
                            multiple={el.multiSelect}
                          />
                          {pagevalues?.architecture_registration ? (
                            <div className="upload_image_title">Uploaded</div>
                          ) : (
                            <div className="upload_image_title">Upload</div>
                          )}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="file_upaloder">
                    <div className="file_upaloder_svg">
                      <SvgCollection {...{ type: "certificate" }} />
                    </div>
                    <div className="file_upaloder_title">
                      Interior Design Degree/Diploma
                    </div>
                    <div className="file_upaloder_uploader">
                      {[
                        {
                          name: "File2",
                          type: "file",
                          multiSelect: false,
                          capture: false,
                          input: {
                            valids: "",
                          },
                          input_filter: [
                            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ],
                          click: () => {},
                        },
                      ].map((el, i) => (
                        <label key={i} className="img_label" htmlFor={el.name}>
                          <input
                            type="file"
                            id={el.name}
                            capture={el.capture}
                            {...{
                              ...(el.input_filter.length
                                ? { accept: el.input_filter }
                                : {}),
                            }}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {
                              formDataImage(e, "interior_design_degree");
                            }}
                            multiple={el.multiSelect}
                          />
                          {pagevalues?.interior_design_degree ? (
                            <div className="upload_image_title">Uploaded</div>
                          ) : (
                            <div className="upload_image_title">Upload</div>
                          )}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="file_upaloder">
                    <div className="file_upaloder_svg">
                      <SvgCollection {...{ type: "certificate" }} />
                    </div>
                    <div className="file_upaloder_title">
                      Architecture Degree/Diploma
                    </div>
                    <div className="file_upaloder_uploader">
                      {[
                        {
                          name: "File3",
                          type: "file",
                          multiSelect: false,
                          capture: false,
                          input: {
                            valids: "",
                          },
                          input_filter: [
                            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ],
                          click: () => {},
                        },
                      ].map((el, i) => (
                        <label key={i} className="img_label" htmlFor={el.name}>
                          <input
                            type="file"
                            id={el.name}
                            capture={el.capture}
                            {...{
                              ...(el.input_filter.length
                                ? { accept: el.input_filter }
                                : {}),
                            }}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => {
                              formDataImage(e, "architecture_degree");
                            }}
                            multiple={el.multiSelect}
                          />
                          {pagevalues?.architecture_degree ? (
                            <div className="upload_image_title">Uploaded</div>
                          ) : (
                            <div className="upload_image_title">Upload</div>
                          )}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="registration_btns">
                <div className="registration_7days">Remind me in 7 days</div>
                <div
                  className="registration_submit"
                  onClick={() => {
                    dispatch({
                      type: "setAppState",
                      subType: "user",
                      payload: {
                        ...user,
                        picture: pagevalues.picture,
                        url: {
                          website: pagevalues.url.website,
                          instagram: pagevalues.url.instagram,
                          linkedin: pagevalues.url.linkedin,
                        },
                        architecture_registration:
                          pagevalues.architecture_registration,
                        interior_design_degree:
                          pagevalues.interior_design_degree,
                        architecture_degree: pagevalues.architecture_degree,

                        // token: user.token,
                      },
                    });
                    nav(`/${type}/getback`);
                  }}
                >
                  Submit
                </div>
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
};
