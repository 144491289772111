// import { LocalData } from "../services/localStorage";

const default_data = {
  user: {},
  team: {},
  flags: {
    token_validated: false,
  },
};
export const data = {
  ...default_data,
};
const AppState = (state = data, { type, subType, payload }) => {
  // const setLocalData = (obj) => {
  //   LocalData.set({
  //     user: state.user,
  //     ...obj,
  //   });
  // };

  // let localData = {};
  // if (!state.app_update) {
  //   localData = LocalData.get();
  //   state = {
  //     ...state,
  //     ...localData,
  //     app_update: true,
  //   };
  // }

  switch (type) {
    case "setAppState":
      switch (subType) {
        case "products":
          return {
            ...state,
            ...payload,
            api: { ...state.api, ...payload?.api },
          };

        case "user":
          // setLocalData({ user: payload });
          return {
            ...state,
            user: payload,
            flags: {
              ...state.flags,
              token_validated: true,
            },
          };
        case "team":
          return {
            ...state,
            team: { ...payload },
          };
        default:
          return state;
      }
    // case "UpdateAppStateFromLocalStorage":
    //   //
    //   localData = LocalData.get();
    //   return { ...state, ...localData, app_update: true };
    default:
      return state;
  }
};
export default AppState;
