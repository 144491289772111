import React, { useState } from "react";
import { LoginNav } from "../../../common/Navbar/LoginNav";
import { useNavigate } from "react-router-dom";

export const SignUpPage = ({ children }) => {
  const nav = useNavigate();
  return (
    <>
      <div className="login_container">
        <LoginNav
          {...{
            data: {
              title: "Already have an account?",
              btnname: "Login",
              onclick: () => {
                nav("/login")
              },
            },
          }}
        />
        {children}
      </div>
    </>
  );
};
