import React, { useState } from "react";
import { RegisStructure } from "../../../common/RegisStructure";
import { useNavigate, useParams } from "react-router-dom";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { CustInput } from "../../../common/CustInput/CustInput";
import { PasswordReset } from "../PasswordReset";
import { PasswordResetSuccess } from "../PasswordResetSuccess";
import { PasswordResetWorning } from "../PasswordResetWorning";

export const AccountIndividual = () => {
  const { page } = useParams();
  const nav = useNavigate();
  const [pagevalues, setpagevalues] = useState({
    picture: "",
    name: "Dhiraj Ambekar",
    email: "dhiraj@email.com",
    phone: "9082279397",
    password: "dhirajambekar",
    registered_address:
      "Flat No. 202, Tower B Green Valley Apartments Sector 12, Dwarka New Delhi - 110075 India",
    shipping_address: "House No.67, Sector 9 R.K Puram New Delhi - 110022",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    picture: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    registered_address: "",
    shipping_address: "",
  });
  const [ResetPassword, setResetPassword] = useState("");
  const formDataImage = (e, type) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setpagevalues((prev) => ({
      ...prev,
      [type]: imageUrl,
    }));
    // ImageToLink(e.target.files[0], ({ status, response }) => {
    //   if (status) {
    //   }
    // });
  };

  return (
    <div className="acc_indi_section">
      {
        {
          warning: (
            <PasswordResetWorning
              {...{
                setResetPassword: setResetPassword,
              }}
            />
          ),
          reset: (
            <PasswordReset
              {...{
                setResetPassword: setResetPassword,
              }}
            />
          ),
          success: (
            <PasswordResetSuccess
              {...{
                setResetPassword: setResetPassword,
              }}
            />
          ),
        }[ResetPassword]
      }
      <RegisStructure
        {...{
          header: {
            head: (
              <div className="acc_indi_backbtn">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    nav(`/`);
                  }}
                >
                  <path
                    d="M17.4166 8.70813H3.49433L7.68462 4.51784C7.76023 4.44481 7.82054 4.35746 7.86203 4.26087C7.90353 4.16428 7.92536 4.0604 7.92628 3.95528C7.92719 3.85017 7.90716 3.74592 7.86735 3.64863C7.82755 3.55133 7.76876 3.46294 7.69443 3.38861C7.6201 3.31428 7.53171 3.2555 7.43442 3.21569C7.33712 3.17589 7.23288 3.15585 7.12776 3.15677C7.02264 3.15768 6.91876 3.17952 6.82218 3.22101C6.72559 3.2625 6.63823 3.32281 6.5652 3.39842L1.02354 8.94009C0.875123 9.08855 0.791748 9.28988 0.791748 9.4998C0.791748 9.70972 0.875123 9.91105 1.02354 10.0595L6.5652 15.6012C6.71451 15.7454 6.91449 15.8252 7.12206 15.8234C7.32964 15.8216 7.5282 15.7383 7.67498 15.5915C7.82176 15.4447 7.90502 15.2462 7.90682 15.0386C7.90862 14.831 7.82883 14.6311 7.68462 14.4818L3.49433 10.2915H17.4166C17.6265 10.2915 17.8279 10.2081 17.9764 10.0596C18.1248 9.91113 18.2082 9.70976 18.2082 9.4998C18.2082 9.28984 18.1248 9.08847 17.9764 8.94001C17.8279 8.79154 17.6265 8.70813 17.4166 8.70813Z"
                    fill="#4D4E58"
                  />
                </svg>
                <div
                  onClick={() => {
                    nav(`/`);
                  }}
                >
                  Account Settings
                </div>
              </div>
            ),
            body: <></>,
          },
          body: (
            <div className="acc_indi_body">
              <div className="acc_indi_body_title">Profile Details</div>
              <div className="acc_indi_image_edit">
                <div className="acc_indi_image">
                  {pagevalues?.picture ? (
                    <div className="upload_image_svg">
                      <div className="svg_design">
                        <img src={pagevalues?.picture} />
                      </div>
                    </div>
                  ) : (
                    <div className="upload_image_svg">
                      <div className="svg_design">
                        <div className="svg_design_inner">
                          <SvgCollection {...{ type: "uploadimage" }} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="acc_indi_edit_btn">
                  {[
                    {
                      name: "Camera",
                      type: "image",
                      multiSelect: false,
                      capture: false,
                      input: {
                        valids: "",
                      },
                      input_filter: ["image/*"],
                      click: () => {},
                    },
                  ].map((el, i) => (
                    <label key={i} className="img_label" htmlFor={el.name}>
                      <input
                        type="file"
                        id={el.name}
                        capture={el.capture}
                        {...{
                          ...(el.input_filter.length
                            ? { accept: el.input_filter }
                            : {}),
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                        onChange={(e) => {
                          formDataImage(e, "picture");
                        }}
                        multiple={el.multiSelect}
                      />
                      <div className="upload_image_title">Edit logo</div>
                    </label>
                  ))}
                </div>
              </div>
              <div className="acc_indi_form">
                <div className="acc_indi_basic_detail">
                  <div>
                    <div className="from_input_title">Name</div>
                    <CustInput
                      {...{
                        inpType: "type2",
                        attr: {
                          type: "text",
                          value: pagevalues.name,
                          error: errpagevalues.name,
                          id: "name",
                          event: {
                            change: ({ target }) => {
                              setpagevalues((prev) => ({
                                ...prev,
                                name: target.value,
                              }));
                              seterrpagevalues((prev) => ({
                                ...prev,
                                name: !target.value,
                              }));
                            },
                          },
                        },
                        data: {
                          placeholder: "Name",
                          prefix: "",
                          type: "",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="from_input_title">Mobile Number</div>
                    <CustInput
                      {...{
                        inpType: "type2",
                        attr: {
                          type: "text",
                          value: pagevalues.phone,
                          error: errpagevalues.phone,
                          id: "phone",
                          event: {
                            change: ({ target }) => {
                              setpagevalues((prev) => ({
                                ...prev,
                                phone: target.value,
                              }));
                              seterrpagevalues((prev) => ({
                                ...prev,
                                phone: !target.value,
                              }));
                            },
                          },
                        },
                        data: {
                          placeholder: "Mobile Number",
                          prefix: "",
                          type: "",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="from_input_title">Email</div>
                    <CustInput
                      {...{
                        inpType: "type2",
                        attr: {
                          type: "text",
                          value: pagevalues.email,
                          error: errpagevalues.email,
                          id: "email",
                          event: {
                            change: ({ target }) => {
                              setpagevalues((prev) => ({
                                ...prev,
                                email: target.value,
                              }));
                              seterrpagevalues((prev) => ({
                                ...prev,
                                email: !target.value,
                              }));
                            },
                          },
                        },
                        data: {
                          placeholder: "Email",
                          prefix: "",
                          type: "",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="from_input_title">Password</div>
                    <CustInput
                      {...{
                        inpType: "type2",
                        attr: {
                          type: "password",
                          value: pagevalues.password,
                          id: "password",
                          event: {
                            change: ({ target }) => {},
                          },
                        },
                        data: {
                          placeholder: "Password",
                          suffix: (
                            <div
                              className="from_input_password"
                              onClick={() => {
                                setResetPassword("warning");
                              }}
                            >
                              Reset Password
                            </div>
                          ),
                          type: "",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="acc_indi_address">
                  <div className="acc_indi_address_title">Address Details</div>
                  <div>
                    <div className="from_input_title">Registered Address</div>
                    <CustInput
                      {...{
                        inpType: "type2",
                        attr: {
                          type: "text",
                          value: pagevalues.registered_address,
                          error: errpagevalues.registered_address,
                          id: "registered_address",
                          event: {
                            change: ({ target }) => {
                              setpagevalues((prev) => ({
                                ...prev,
                                registered_address: target.value,
                              }));
                              seterrpagevalues((prev) => ({
                                ...prev,
                                registered_address: !target.value,
                              }));
                            },
                          },
                        },
                        data: {
                          placeholder: "Registered Address",
                          prefix: "",
                          type: "",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <div className="from_input_title">Shipping Address</div>
                    <CustInput
                      {...{
                        inpType: "type2",
                        attr: {
                          type: "text",
                          value: pagevalues.shipping_address,
                          error: errpagevalues.shipping_address,
                          id: "shipping_address",
                          event: {
                            change: ({ target }) => {
                              setpagevalues((prev) => ({
                                ...prev,
                                shipping_address: target.value,
                              }));
                              seterrpagevalues((prev) => ({
                                ...prev,
                                shipping_address: !target.value,
                              }));
                            },
                          },
                        },
                        data: {
                          placeholder: "Shipping Address",
                          prefix: "",
                          type: "",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="acc_indi_btn">Save Changes</div>
            </div>
          ),
        }}
      />
    </div>
  );
};
