import React, { useState } from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { useNavigate } from "react-router-dom";
import { CustInput } from "../../../common/CustInput/CustInput";

export const ResetPassword = () => {
  const nav = useNavigate();
  const [pagevalues, setpagevalues] = useState({
    password: "",
    confirmpassword: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    password: "",
    confirmpassword: "",
  });
  const [password, setPassword] = useState(true);
  return (
    <div className="forget_pass_section">
      <div
        className="back_btn"
        onClick={() => {
          nav("/login/forgetpassword");
        }}
      >
        <SvgCollection {...{ type: "leftarrow" }} />
        Back
      </div>{" "}
      <div className="forget_pass_title">Recover your Password</div>
      <div className="forget_pass_desc">
        <span>Almost Done! </span>
        Create your new password and you are ready to go
      </div>
      <div>
        <CustInput
          {...{
            inpType: "type2",
            attr: {
              type: password ? "password" : "text",
              value: pagevalues.password,
              error: errpagevalues.password,
              id: "password",
              event: {
                change: ({ target }) => {
                  setpagevalues((prev) => ({
                    ...prev,
                    password: target.value,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    password: !target.value,
                  }));
                },
              },
            },
            data: {
              placeholder: "Create Password",
              prefix: "",
              type: "",
            },
          }}
        />
      </div>
      <div className="confirm_password">
        <CustInput
          {...{
            inpType: "type2",
            attr: {
              type: password ? "password" : "text",
              value: pagevalues.confirmpassword,
              error: errpagevalues.confirmpassword,
              id: "condirmpassword",
              event: {
                change: ({ target }) => {
                  setpagevalues((prev) => ({
                    ...prev,
                    confirmpassword: target.value,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    confirmpassword: !target.value,
                  }));
                },
              },
            },
            data: {
              placeholder: "Confirm Your Password",
              prefix: "",
              type: "",
              suffix: (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPassword((prev) => !prev);
                  }}
                >
                  <SvgCollection {...{ type: "eye" }} />
                </div>
              ),
            },
          }}
        />
      </div>
      <div className="forget_pass_ctr">
        <div
          className="forget_pass_send_link"
          onClick={() => {
            nav("/login/welcome");
          }}
        >
          Send Link
        </div>
        <div className="forget_pass_need">
          Need further assistance?
          <span>Contact Us</span>
        </div>
      </div>
    </div>
  );
};
