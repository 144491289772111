import React, { useEffect, useState } from "react";
import { SvgCollection } from "../../common/Image/SvgCollection";
import { CustInput } from "../../common/CustInput/CustInput";
import { useDispatch, useSelector } from "react-redux";

export const AddEditAccount = ({ AddEditAcc, setAddEditAcc }) => {
  const { team } = useSelector((state) => state.appState);
  const dispatch = useDispatch();
  const [pagevalues, setpagevalues] = useState({
    name: "",
    email: "",
    phone: "",
    designation: "",
    picture: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    picture: "",
    name: "",
    email: "",
    phone: "",
    designation: "",
  });
  const formDataImage = (e, type) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setpagevalues((prev) => ({
      ...prev,
      [type]: imageUrl,
    }));
    // ImageToLink(e.target.files[0], ({ status, response }) => {
    //   if (status) {
    //   }
    // });
  };
  useEffect(() => {
    setpagevalues({
      ...team.edituser,
    });
  }, [team.edituser]);

  return (
    <div
      className="add_edit_acc_modal"
      onClick={() => {
        setAddEditAcc(false);
      }}
    >
      <div
        className="add_edit_acc_modal_box"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="add_edit_acc_modal_heading">
          <div>
            <SvgCollection
              {...{
                type: "edit_pen",
              }}
            />
          </div>
          <div className="add_edit_acc_modal_heading_sep"></div>
          <div>
            <div className="add_edit_acc_modal_heading_title">Edit Details</div>
            <div className="add_edit_acc_modal_heading_desc">
              Enter the details to update across your profile
            </div>
          </div>
          <div className="add_edit_acc_modal_image">
            <div className="add_edit_acc_modal_up_btn">
              {[
                {
                  name: "Camera",
                  type: "image",
                  multiSelect: false,
                  capture: false,
                  input: {
                    valids: "",
                  },
                  input_filter: ["image/*"],
                  click: () => {},
                },
              ].map((el, i) => (
                <label key={i} className="img_label" htmlFor={el.name}>
                  <input
                    type="file"
                    id={el.name}
                    capture={el.capture}
                    {...{
                      ...(el.input_filter.length
                        ? { accept: el.input_filter }
                        : {}),
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onChange={(e) => {
                      formDataImage(e, "picture");
                    }}
                    multiple={el.multiSelect}
                  />
                  <div className="upload_image_title">Upload</div>
                </label>
              ))}
            </div>
            <div className="add_edit_acc_modal_up_img">
              {pagevalues.picture ? (
                <img src={pagevalues.picture} />
              ) : (
                <SvgCollection
                  {...{
                    type: "uploadimage",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="add_edit_acc_modal_body">
          <div>
            <div className="from_input_title">Name</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.name,
                  error: errpagevalues.name,
                  id: "name",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        name: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        name: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <div className="from_input_title">Email ID</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.email,
                  error: errpagevalues.email,
                  id: "email",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        email: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        email: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <div className="from_input_title">Mobile Number</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.phone,
                  error: errpagevalues.phone,
                  id: "phone",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        phone: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        phone: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>

          <div>
            <div className="from_input_title">Designation</div>
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.designation,
                  id: "designation",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        designation: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        designation: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "",
                  suffix: "",
                  type: "",
                },
              }}
            />
          </div>
        </div>
        <div className="add_edit_acc_modal_box_btns">
          <div
            className="add_edit_acc_modal_box_btns_cnl"
            onClick={() => {
              setAddEditAcc(false);
            }}
          >
            Cancel
          </div>
          <div
            className="add_edit_acc_modal_box_btns_conf"
            onClick={() => {
              if (team.edituser.adduser) {
                dispatch({
                  type: "setAppState",
                  subType: "team",
                  payload: {
                    list: [...team.list, pagevalues],
                  },
                });
              } else {
                dispatch({
                  type: "setAppState",
                  subType: "team",
                  payload: {
                    list: [
                      ...team.list.filter((el, i) => i != team.edituser.index),
                      pagevalues,
                    ],
                  },
                });
              }
              setAddEditAcc(false);
            }}
          >
            Update
          </div>
        </div>
      </div>
    </div>
  );
};
