import React from "react";

import { Outlet, useNavigate, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginPages } from "./pages/CreateAccount/Login/LoginPages";
import { SignUpPage } from "./pages/CreateAccount/Signup/SignUpPage";
import { CommonSignup } from "./pages/CreateAccount/Signup/CommonSignup";
import { CommonLogin } from "./pages/CreateAccount/Login/CommonLogin";
import { AccountPage } from "./pages/Account/AccountPage";
import { AccountNav } from "./pages/Account/AccountNav";
import { HomePage } from "./pages/Home/HomePage";

// import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state.appState);

  const nav = [
    {
      title: "signup",
      path: "",
      element: (
        <SignUpPage>
          <Outlet />
        </SignUpPage>
      ),
      children: [
        {
          path: ":type?/:page?",
          element: <CommonSignup />,
        },
      ].flat(),
    },
    {
      title: "login",
      path: "login",
      element: (
        <LoginPages>
          <Outlet />
        </LoginPages>
      ),
      children: [
        {
          path: ":page?",
          element: <CommonLogin />,
        },
      ].flat(),
    },
    {
      title: "account",
      path: "account",
      element: (
        <AccountNav>
          <Outlet />
        </AccountNav>
      ),
      children: [
        {
          path: ":type?/:subtype?/:currentshow?",
          element: <AccountPage />,
        },
      ].flat(),
    },
    {
      title: "home",
      path: "home/:type?/:subtype?",
      element: <HomePage />,
    },
  ];
  const routes = [
    // {
    //   path: "",
    //   element: <LoginPages />,
    // },
    ...nav,
    {
      path: "/*",
      element: "Error",
    },
  ];

  return <div className="layout">{useRoutes(routes)}</div>;
};

export default Layout;
