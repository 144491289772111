import React, { useState } from "react";
import { CustInput } from "../../../common/CustInput/CustInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SvgCollection } from "../../../common/Image/SvgCollection";

export const IndividualDetail = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState(true);
  const [pagevalues, setpagevalues] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    create_password: "",
    confirm_password: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    create_password: "",
    confirm_password: "",
  });
  return (
    <>
      <div className="company_detail_section">
        <div
          className="back_btn"
          onClick={() => {
            nav("/");
          }}
        >
          <SvgCollection {...{ type: "leftarrow" }} />
          Back
        </div>
        <div className="comp_detl_title">Let’s get started</div>
        <div className="comp_detl_desc">
          Enter your details and we can get you set up and ready to go.
        </div>
        <div className="comp_detl_social_icons">
          <div>
            <SvgCollection {...{ type: "google" }} />
          </div>
          <div>
            <SvgCollection {...{ type: "instagramcolor" }} />
          </div>
        </div>
        <div className="comp_detl_or_continue">
          <div></div>
          Or continue with
          <div></div>
        </div>
        <div className="comp_detl_form">
          <div className="comp_detl_form_names">
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.fname,
                  error: errpagevalues.fname,
                  id: "fname",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        fname: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        fname: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "First Name*",
                  prefix: "",
                  type: "",
                },
              }}
            />
            <CustInput
              {...{
                inpType: "type2",
                attr: {
                  type: "text",
                  value: pagevalues.lname,
                  error: errpagevalues.lname,
                  id: "lname",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        lname: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        lname: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Last Name",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>

          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.email,
                error: errpagevalues.email,
                id: "email",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      email: target.value,
                    }));
                    seterrpagevalues({
                      email:
                        !target.value ||
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                          target.value
                        ),
                    });
                  },
                },
              },
              data: {
                placeholder: "Email*",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: "text",
                value: pagevalues.phone,
                error: errpagevalues.phone,
                id: "phone",
                event: {
                  change: ({ target }) => {
                    if (!/^\d*$/i.test(target.value)) {
                      return;
                    }
                    const cleanedInput = target.value;
                    if (cleanedInput.length > 10) {
                      return;
                    }
                    setpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput.length != 10,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Phone Number*",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: password ? "password" : "text",
                value: pagevalues.create_password,
                error: errpagevalues.create_password,
                id: "create_password",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      create_password: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      create_password: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Create Password",
                prefix: "",
                type: "",
              },
            }}
          />
          <CustInput
            {...{
              inpType: "type2",
              attr: {
                type: password ? "password" : "text",
                value: pagevalues.confirm_password,
                error: errpagevalues.confirm_password,
                id: "confirm_password",
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      confirm_password: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      confirm_password: !target.value,
                    }));
                  },
                },
              },
              data: {
                placeholder: "Confirm Password",
                prefix: "",
                type: "",
                suffix: (
                  <div
                    className="password"
                    onClick={() => {
                      setPassword((prev) => !prev);
                    }}
                  >
                    <svg
                      width="22"
                      height="14"
                      viewBox="0 0 22 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.18829 6.9995C6.18829 4.3474 8.34548 2.18932 11.0004 2.18932C13.6526 2.18932 15.8097 4.34736 15.8097 6.9995C15.8097 9.6522 13.6526 11.8093 11.0004 11.8093C8.34543 11.8093 6.18829 9.6522 6.18829 6.9995ZM0.337588 6.54059C0.17165 6.84073 0.17165 7.15826 0.337588 7.45868C2.49192 11.3273 6.57735 13.7305 11.0004 13.7305C15.4206 13.7305 19.5061 11.3273 21.6604 7.45868C21.8292 7.15826 21.8292 6.84073 21.6604 6.54059C19.5061 2.672 15.4206 0.269043 11.0004 0.269043C6.57735 0.269043 2.49192 2.672 0.337588 6.54059ZM11.0004 3.76573C9.2152 3.76573 7.76465 5.21623 7.76465 6.9995C7.76465 8.78271 9.21515 10.2333 11.0004 10.2333C12.7828 10.2333 14.2333 8.78276 14.2333 6.9995C14.2333 5.21623 12.7828 3.76573 11.0004 3.76573Z"
                        fill="#B8B8B8"
                      />
                    </svg>
                  </div>
                ),
              },
            }}
          />
        </div>
        <div className="comp_detl_terms">
          By Clicking “Continue”, You Agree to Our <span>Terms of Use</span> and{" "}
          <span>Privacy Policy</span>
        </div>
        <div
          className="comp_detl_btn"
          onClick={() => {
            // if (
            //   !pagevalues.fname.trim() ||
            //   !pagevalues.lname.trim() ||
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
            //     pagevalues.email
            //   ) ||
            //   pagevalues.phone.length < 10 ||
            //   !pagevalues.create_password ||
            //   !pagevalues.confirm_password ||
            //   pagevalues.confirm_password != pagevalues.create_password
            // ) {
            //   seterrpagevalues((prev) => ({
            //     ...prev,
            //     fname: !pagevalues.fname.trim(),
            //     lname: !pagevalues.lname.trim(),
            //     email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
            //       pagevalues.email
            //     ),
            //     phone: pagevalues.phone.length < 10,
            //     create_password: !pagevalues.create_password,
            //     confirm_password:
            //       !pagevalues.confirm_password ||
            //       pagevalues.confirm_password != pagevalues.create_password,
            //     // confirm_password:
            //     //   pagevalues.confirm_password != pagevalues.create_password,
            //   }));
            //   return;
            // }
            dispatch({
              type: "setAppState",
              subType: "user",
              payload: {
                name: pagevalues.fname + " " + pagevalues.lname,
                fname: pagevalues.fname,
                lname: pagevalues.lname,
                email: pagevalues.email,
                phone: pagevalues.phone,
                create_password: pagevalues.create_password,
                confirm_password: pagevalues.confirm_password,
                type: "company",
                // token: user.token,
              },
            });
            nav("/individual/verify-otp");
          }}
        >
          Continue
        </div>
      </div>
    </>
  );
};
