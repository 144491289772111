import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeftSection } from "../LeftSection";
import { LoginSection } from "./LoginSection";
import { PasswordSection } from "./PasswordSection";
import { LoginVerifyOtp } from "./LoginVerifyOtp";
import { ForgetPassword } from "./ForgetPassword";
import { ResetPassword } from "./ResetPassword";
import { WelcomeResetPg } from "./WelcomeResetPg";

export const CommonLogin = () => {
  const { page = "" } = useParams();
  const SliderData = [
    {
      top_img: "https://imgur.com/JlpAbJn.png",
      bottom_title: "CREATE & MANAGE ALL YOUR PROJECTS",
      bottom_desc: "Get Organized and Stay on Top of Your Projects with Ease!",
    },
    {
      top_img: "https://imgur.com/JlpAbJn.png",
      bottom_title: "CREATE & MANAGE ALL YOUR PROJECTS",
      bottom_desc: "Get Organized and Stay on Top of Your Projects with Ease!",
    },
  ];
  // const [nav, setnav] = useState("");
  // useEffect(() => {
  //   if (type) {
  //     setnav(page);
  //     if (type == "company" && page == "detail") {
  //       setnav("company_detail");
  //     }
  //     if (type == "individual" && page == "detail") {
  //       setnav("individual_detail");
  //     }
  //   }
  // }, [type, page]);

  const PageList = {
    "": <LoginSection />,
    password: <PasswordSection />,
    "verify-otp": <LoginVerifyOtp />,
    forgetpassword: <ForgetPassword />,
    resetpassword: <ResetPassword />,
    welcome: <WelcomeResetPg />,
  };

  return (
    <div className="login_page_container">
      {page != "registration" ? (
        <div className="login_left">
          <LeftSection
            {...{
              SliderData,
            }}
          />
        </div>
      ) : (
        ""
      )}
      <div className="login_right">
        {/* {PageList.filter((el) => el.name == nav).map((el, i) => el.comp)} */}

        {PageList[page]}
      </div>
    </div>
  );
};
