import React, { useState } from "react";
import { SvgCollection } from "../../../common/Image/SvgCollection";
import { useNavigate, useParams } from "react-router-dom";
import { CustInput } from "../../../common/CustInput/CustInput";

export const LoginSection = () => {
  const nav = useNavigate();
  const { page } = useParams();
  const [login_key, setlogin_key] = useState("");
  const [errlogin_key, seterrlogin_key] = useState("");

  return (
    <div className="login_section">
      <div
        className="back_btn"
        onClick={() => {
          nav("/");
        }}
      >
        <SvgCollection {...{ type: "leftarrow" }} />
        Back
      </div>
      <div className="login_section_title">Login</div>
      <div className="login_section_desc">
        Login to Explore Your Favourite Materials?
      </div>
      <div>
        <CustInput
          {...{
            inpType: "type2",
            attr: {
              type: "text",
              value: login_key,
              error: errlogin_key,
              id: "name",
              event: {
                change: ({ target }) => {
                  setlogin_key(target.value);
                  seterrlogin_key(!target.value);
                },
              },
            },
            data: {
              placeholder: "Email / Mobile Number",
              prefix: "",
              type: "",
            },
          }}
        />
      </div>
      <div
        className="login_section_btn"
        onClick={() => {
          nav(`/login/password`);
        }}
      >
        Continue
      </div>
      <div className="login_section_sepreter">
        <div className="login_sepreter_line"></div>
        <div className="login_sepreter_title">Or continue with</div>
      </div>
      <div className="login_sepreter_social_icons">
        <div>
          <SvgCollection {...{ type: "google" }} />
        </div>
        <div>
          <SvgCollection {...{ type: "instagramcolor" }} />
        </div>
      </div>
    </div>
  );
};
