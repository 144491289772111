import React from "react";
import { CustInput } from "../../common/CustInput/CustInput";
import { SvgCollection } from "../../common/Image/SvgCollection";

export const PasswordResetWorning = ({ setResetPassword }) => {
  return (
    <div
      className="pass_warning_modal"
      onClick={() => {
        setResetPassword(false);
      }}
    >
      <div
        className="pass_reset_modal_box"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pass_reset_modal_heading">
          <div>
            <SvgCollection
              {...{
                type: "key",
              }}
            />
          </div>
          <div className="pass_reset_modal_heading_sep"></div>
          <div>
            <div className="pass_reset_modal_heading_title">Password Reset</div>
            <div className="pass_reset_modal_heading_desc">
              Are you sure you want to reset the password?
            </div>
          </div>
        </div>

        <div className="pass_reset_modal_box_btns">
          <div
            className="pass_reset_modal_box_btns_cnl"
            onClick={() => {
              setResetPassword("");
            }}
          >
            Cancel
          </div>
          <div
            className="pass_reset_modal_box_btns_conf"
            onClick={() => {
              setResetPassword("reset");
            }}
          >
            Reset
          </div>
        </div>
      </div>
    </div>
  );
};
